import React,{Suspense} from 'react';
import { useRoutes  } from "react-router-dom";
import routes from './router'
import { Spin } from 'antd';
import store from './store';
import { Provider } from 'react-redux';
import './global'

function App() {
 

  return (
    <Provider store={store}>
      <Suspense fallback={<Spin className='app_spin' />}>{useRoutes(routes)}</Suspense>
    </Provider>
  );
}

export default App;
