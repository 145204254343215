import HttpRequest from './axios'

//查询全部的训练计划资源列表接口
export const allTrainPlanSource = (params: { onlyLocal: number; sourceType: string }): promiseType<trainPlanSourceItf[]> => HttpRequest({
  url: '/managerApi/trainPlan/allTrainPlanSource',
  method: 'get',
  params
})
//训练资源启用-禁用接口
export const changeAbleStatus = (params: { ableStatus: string; id: number; }): promiseType<any> => HttpRequest({
  url: '/managerApi/trainPlan/changeAbleStatus',
  method: 'get',
  params
})
//删除训练资源接口
export const removeLocalSource = (params:{id:number}):promiseType<any> =>HttpRequest({
  url:'/managerApi/trainPlan/removeLocalSource',
  method:'get',
  params
})
//下载训练资源到本地服务器接口
export const sourceToLocal = (params:{id:number}):promiseType<any> =>HttpRequest({
  url:'/managerApi/trainPlan/sourceToLocal',
  method:'get',
  params
})
//查询资源下载进度接口
export const syncProcess = (params:{id:number}):promiseType<syncProcessItf> =>HttpRequest({
  url:'/managerApi/trainPlan/syncProcess',
  method:'get',
  params
})
//新增自定义训练资源接口
export const addCustomerTrainPlanSource = (params:addCustomerTrainPlanParam):promiseType<any> =>HttpRequest({
  url:'/managerApi/trainPlan/addCustomerTrainPlanSource',
  method:'post',
  data:params
})
//设置患者训练计划接口
export const putPatientTrainPlan = (params:{patientId:string;trainPlanList:{difficulty:string;showIndex:number | null;sourceId:number;}[]}):promiseType<any> =>HttpRequest({
  url:'/managerApi/trainPlan/putPatientTrainPlan',
  method:'post',
  data:params
})
//查询资源类型配置接口
export const sourceTypeConfig = ():promiseType<sourceTypeConfigResItf[]> =>HttpRequest({
  url:'/managerApi/trainPlan/sourceTypeConfig',
  method:'get'
})
//查询可用的自定义训练资源图片接口
export const ableCustomerTrainPlanSourceImg = ():promiseType<ableCustomerTrainPlanSourceImgItf[]> =>HttpRequest({
  url:'/managerApi/trainPlan/ableCustomerTrainPlanSourceImg',
  method:'get'
})
//修改自定义训练资源接口
export const updateCustomerTrainPlanSource = (params:addCustomerTrainPlanParam):promiseType<any> =>HttpRequest({
  url:'/managerApi/trainPlan/updateCustomerTrainPlanSource',
  method:'post',
  data:params
})