import { lStorage } from "../utils/utils"


export const sourceTypeConfigFn = (type: string) => {
  const sourceTypeConfig = lStorage.get('sourceTypeConfig')
  // console.log('sourceTypeConfig',sourceTypeConfig)
  let sourceLabel = ''
  sourceTypeConfig?.forEach((item: any) => {
    if (item.sourceType == type) {
      sourceLabel = item.sourceTypeDiscretion
    }
  });
  return sourceLabel;
}

export const sourceTypeColor: any = {
  '0': 'volcano',
  '1': 'green',
  '2': 'blue',
  '3': 'purple',
  '4': 'gold',
  '5': 'magenta',
  '6': 'cyan',
  '7': 'geekblue',
  '8': 'orange'
}