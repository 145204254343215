import React, { lazy } from 'react';
import { RouteObject, Navigate } from 'react-router-dom';


const Login = lazy(() => import('../views/login/index'));
const Notfound = lazy(() => import('../views/common/Notfound'));
const Container = lazy(() => import('../views/container'));
const Patient = lazy(() => import('../views/patient/list'));
const AddPatient = lazy(() => import('../views/patient/add'));
const PatientDetalis = lazy(() => import('../views/patient/details'));
const MedicalRecord = lazy(() => import('../views/patient/medicalRecord'));
const PatientReport = lazy(() => import('../views/patient/report'));
const EegReport = lazy(() => import('../components/eegReport/eegReport'));
const Course = lazy(() => import('../views/course/list'));
const CourseManage = lazy(() => import('../views/course/manage'));
const Report = lazy(() => import('../views/report/list'));
const ReportDetails = lazy(() => import('../views/report/details'));
const User = lazy(() => import('../views/user/list'));
const AddUser = lazy(() => import('../views/user/add'));
const Role = lazy(() => import('../views/role/list'));

const routes: RouteObject[] = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '*',
    element: <Notfound />
  },
  {
    path: '/',
    element: <Container />,
    children: [
      {
        path: '/',
        element: <Navigate to="patient" replace />
      },
      {
        path: '/patient',
        element: <Patient />
      },
      {
        path: 'course',
        element: <Course />
      },
      {
        path: '/report',
        element: <Report />
      },
      {
        path: '/user',
        element: <User />
      },
      {
        path: '/role',
        element: <Role />
      }
    ]
  },
  {
    path: '/add-patient',
    element: <AddPatient />
  },
  {
    path: '/patient-details',
    element: <PatientDetalis />
  },
  {
    path:'/medical-record',
    element:<MedicalRecord />
  },
  {
    path:'/patient-report',
    element:<PatientReport />
  },
  {
    path:'/eeg-report',
    element:<EegReport />
  },
  {
    path:'/course-manage',
    element:<CourseManage />
  },
  {
    path:'/report-details',
    element:<ReportDetails />
  },
  {
    path:'/add-user',
    element:<AddUser />
  }
]

export default routes;