import { sourceTypeConfigFn } from "../config/sourceTypes"

export const lStorage = {
  set(key: string, val: any) {
    window.localStorage.setItem(key, JSON.stringify(val))
  },
  get(key: string) {
    let val = window.localStorage.getItem(key)
    try {
      return JSON.parse(val as string)
    } catch (error) {
      return val
    }
  },
  remove(key: string) {
    window.localStorage.removeItem(key);
  },
  clear() {
    window.localStorage.clear();
  }
}

export const toTreeData = (arr: any, key: string, indexOfKey: string, imgUrlKey?: string) => {
  let treeData = arr.reduce((cur: any, next: any) => {
    const obj = cur.find((curItem: any) => curItem[key] === next[key])
    if (obj) {
      if (obj.children.indexOf(next[indexOfKey]) === -1) {
        obj.children.push({
          ...next
        })
      }
      if (imgUrlKey) {
        if (obj.imgUrls.indexOf(next[imgUrlKey]) === -1) {
          obj.imgUrls.push(next[imgUrlKey])
        }
      }

    } else {
      let newObj
      if (imgUrlKey) {
        newObj = {
          [key]: next[key],
          children: [{
            ...next
          }],
          imgUrls: [next[imgUrlKey]]
        }
      } else {
        newObj = {
          [key]: next[key],
          children: [{
            ...next
          }]
        }
      }

      cur.push(newObj)
    }
    return cur
  }, [])
  return treeData
}

//训练疗法--课程数据转成树状格式数据
export const courseDataToTree = (arr: any, key: string, id: string,) => {
  let treeData = arr.reduce((cur: any, next: any) => {
    const obj = cur.find((curItem: any) => curItem[key] === next[key])
    if (obj) {
      if (obj.children.indexOf(next[id]) === -1) {
        obj.children.push({
          'value':next[id],
          'label':next['sourceName'],
          ...next
        })
      }
    } else {
      let newObj = {
        [key]: next[key],
        'key': next[key],
        'value': next[key],
        // 'label':sourceTypes[next[key]],
        'label':sourceTypeConfigFn(next[key]),
        children: [{
          'value':next[id],
          'label':next['sourceName'],
          ...next
        }]
      }
      
      cur.push(newObj)
    }
    return cur
  }, [])
  return treeData
}

//树形结果数据根据孩子id获取父级id
export const getParentIdByChildId = (tree:any, childId:number,pId:string) =>{
  let parentIdMap:any = {};
  const traverse = (nodes:any, parentId:string | null) => {
    nodes.forEach((node:any) => {
      parentIdMap[node[pId]] = parentId;
      if (node.children && node.children.length) {
        traverse(node.children, node[pId]);
      }
    });
  };
  traverse(tree, null);
  return parentIdMap[childId];
}