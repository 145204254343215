import { configureStore } from "@reduxjs/toolkit"
import tokenStoreReducer from './reducers/setToken'
import sourceTypeConfigStoreReducer from './reducers/sourceTypeConfig'

const store = configureStore({
    reducer:{
        token:tokenStoreReducer,
        sourceTypeConfig:sourceTypeConfigStoreReducer
    }
})

export default store