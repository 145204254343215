import { createSlice } from "@reduxjs/toolkit";

const tokenStore = createSlice({
  name: 'setToken',
  initialState: {
    token: localStorage.getItem('adhd-token') || ''
  },
  reducers: {
    setToken(state, actions) {
      state.token = actions.payload;
      localStorage.setItem('adhd-token', actions.payload)
      console.log("state.token", state.token);
    }
  }
})

export const { setToken } = tokenStore.actions;
export default tokenStore.reducer