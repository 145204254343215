import { createSlice } from "@reduxjs/toolkit";
import { sourceTypeConfig } from "../../api/course";

const sourceTypeConfigStore = createSlice({
  name: 'setSourceTypeConfig',
  initialState: {
    sourceTypeConfig: []
  },
  reducers: {
    setSourceTypeConfig(state, actions) {
      state.sourceTypeConfig = actions.payload;
      localStorage.setItem('sourceTypeConfig', JSON.stringify(actions.payload))
    }
  }
})

export const getSourceTypeConfigAction = () => async (dispatch:any) => {
  await sourceTypeConfig().then(res =>{
    console.log('sourceTypeConfig-data',res)
    if(res && res.code == 0){
      dispatch(setSourceTypeConfig(res.data))
    }
  })
  
}

export const { setSourceTypeConfig } = sourceTypeConfigStore.actions;
export default sourceTypeConfigStore.reducer
// export default sourceTypeConfigStore

