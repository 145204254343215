import axios from "axios";
import { lStorage } from '../utils/utils'
import { message } from 'antd';
import { BASE_URL } from './baseUrl'



// const BASE_URL = 'http://brainbioinfo.eegsmart.com'

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 3600000,
  withCredentials:false
})

//请求拦截器
instance.interceptors.request.use(config => {
  let token = lStorage.get('adhd-token');
  if (token) {
    config.headers = config.headers || {};
    config.headers["token"] = token;
  }
  return config
}, err => {
  return Promise.reject(err)
})

//响应拦截器
instance.interceptors.response.use(result =>{
  console.log('result',result)
  let body  = result.data;
  if(body && (body.code == 100 || body.CODE == 100) || (body.code == 101 || body.CODE == 101)){
    message.warning((window as any).setCodeToMsgTxt(body.msg));
    if(window.location.pathname =='/login')return;
    lStorage.remove('adhd-token');
    lStorage.remove('cfg-map');
    window.location.href = '/login';
    
  }
  return result.data

},err =>{
  return Promise.reject(err)
})

export default instance;
