
//定义全局方法---系统编码及含义对照表，根据后端返回消息编码查找对一个的文字消息
window.setCodeToMsgTxt = (code:string) =>{
    let cfgMapObj = window.localStorage.getItem("cfg-map") ? JSON.parse(window.localStorage.getItem("cfg-map") || '') : {};
  //   console.log('cfgMapObj',cfgMapObj)
  // let bodyMsg = ''
  // for(let key in cfgMapObj){
  //   if(code == key){
  //     bodyMsg =  cfgMapObj[key];
  //   }
  // }
  return cfgMapObj[code];
} 